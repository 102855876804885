@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "HelloZone";
    src: url("./HelloZone.otf");
  }

  .discord {
    bottom: 0;
    left: 0;
    padding-bottom: 2em;
    position: fixed;
    width: 2vw;
  }
  .twitter {
    bottom: 0;
    left: 0;
    padding-bottom: 4em;
    position: fixed;
    width: 2vw;
  }
  .github {
    bottom: 0;
    left: 0;
    padding-bottom: 6em;
    position: fixed;
    width: 2vw;
  }
  .linkedin {
    bottom: 0;
    left: 0;
    padding-bottom: 8em;
    position: fixed;
    width: 2vw;
  }